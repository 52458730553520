<template>
  <f-card
    :header="APP_TITLE"
    header-center
  >
    <f-form @submit="onSubmit">
      <f-input
        v-model="email"
        :label="$t('labels.email')"
        rules="required|email"
        type="email"
      />
      <f-input
        v-model="password"
        :label="$t('labels.password')"
        rules="required"
        type="password"
      />
      <f-form-group>
        <router-link
          class="float-left small mt-1"
          :to="{ name: 'forgotPassword' }"
        >
          <i class="fa fa-key mr-2" />{{ $t('labels.forgotPassword') }}
        </router-link>
      </f-form-group>
      <div class="form-footer mt-5">
        <f-button
          :label="$t('labels.login')"
          type="submit"
          variant="primary btn-block"
        />
      </div>
    </f-form>
  </f-card>
</template>

<script>
import { ROOT_API, APP_TITLE } from '@/config/app.config'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      email: '',
      password: '',
      storeFunctionName: ROOT_API ? 'login' : 'fakeLogin',
      isProduction: process.env.NODE_ENV === 'production',
      APP_TITLE
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  async mounted() {
    if (this.isProduction) {
      await this.$recaptchaLoaded()
    }
  },
  methods: {
    async onSubmit() {
      let recaptchaToken

      if (this.isProduction) {
        recaptchaToken = await this.$recaptcha('login')
      }

      await this.login(this.email, this.password, recaptchaToken)
    },
    async login(email, password, recaptchaToken) {
      await this.$store.dispatch(this.storeFunctionName, {
        email,
        password,
        recaptchaToken
      })

      if (this.$route.query.returnUrl) {
        this.$router.push({
          path: this.$route.query.returnUrl,

        })
      } else {
        this.$router.push({
          name: 'pendingFeatures',
          params: {
            companySlug: this.user?.company?.slug,
            projectSlug: this.user.projects[0]?.slug
          }
        })
      }
    }
  }
}
</script>
